import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { authenticate, signupUser, confirmUser} from './authenticate';
import userpool from './userpool';

const pcontainer = styled.div`
  display:flex;
 height;100vh;
 background-color:red;
 
`;

const CenteredH1 = styled.h1`
  text-align: center;
`;


const Container = styled.div`
display:flex;
flex-direction:column;
max-width: 320px;
margin: 0 auto;
padding: 20px;
border-radius: 5px;
align-items: center;
height:100%
width:100%;
z-index:100;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);


  @media (max-width: 600px) {

    margin:auto 0;
  }

  @media (min-width: 300px) {

    margin:auto;
  }
`;

const StyledButton = styled.button`
  background-color: #6200ea;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #3700b3;
  }

  @media (max-width: 600px) {
    padding: 8px 16px;
    margin: 8px;
  }
`;

const StyledInput = styled.input`
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;

  @media (max-width: 600px) {
    padding: 8px;
    margin: 8px;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 12px;

  @media (max-width: 600px) {
    font-size: 10px;
  }
`;

const Signup = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [newUser, setNewUser] = useState(null)
  const [code, setCode] = useState(null)
  const formInputChange = (formField, value) => {
    if (formField === 'email') {
      setEmail(value);
    }
    if (formField === 'password') {
      setPassword(value);
    }
    if (formField === 'confirmpassword'){
        setConfirm(value)
    }
    if (formField == 'code'){
      setCode(value)
    }
  };

 const validation = () => {
    return new Promise((resolve, reject) => {
      if (email === '' && password === '') {
        setEmailErr('Email is Required');
        setPasswordErr('Password is required');
        resolve({ email: 'Email is Required', password: 'Password is required' });
      } else if (email === '') {
        setEmailErr('Email is Required');
        resolve({ email: 'Email is Required', password: '' });
      } else if (password === '') {
        setPasswordErr('Password is required');
        resolve({ email: '', password: 'Password is required' });
      } else if (password.length < 6) {
        setPasswordErr('must be 6 characters');
        resolve({ email: '', password: 'must be 6 characters' });
      } else {
        resolve({ email: '', password: '' });
      }
      reject('');
    });
  };
  function validateForm() {
    return (
      email.length > 0 &&
      password.length > 0 &&
      password.length == confirm.length &&
      password === confirm
    );
  }
  
  function validateConfirmationForm() {

    if (!code){return false}
    return code.length > 0;
  }
  function handleConfirmationSubmit(e){
    e.preventDefault();

    let result =  validateConfirmationForm()

    if(result){
      confirmUser(email, code)
      navigate('/analysis')

    }

  }
 
  const handleClick = (e) => {
    e.preventDefault();

    setEmailErr('');
    setPasswordErr('');
    validation().then((res) => {
      if(res.email === '' && res.password === ''){
 
        signupUser(email, password)
        setNewUser(true)
      }
    })
  };
function renderConfirmationForm(){
  return(
  <form >
    <Container>
      <h3>Confirmation Code</h3>
      <StyledInput
        type="text"
        placeholder="Confirmation Code"
        value={code}
        onChange={(e) => formInputChange('code', e.target.value)}
      />
            <StyledButton onClick={handleConfirmationSubmit}>Confirm</StyledButton>

      <h4> check your email for the code</h4>
    </Container>
  </form>
  )
}
  function renderForm(){
    return(
    <form onSubmit={handleClick}>
       <Container>
<CenteredH1>Signup</CenteredH1>
      <StyledInput
        type="text"
        placeholder="email"
        value={email}
        onChange={(e) => formInputChange('email', e.target.value)}
      />
      {emailErr && <ErrorText>{emailErr}</ErrorText>}
      <StyledInput
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => formInputChange('password', e.target.value)}
      />

    <StyledInput
        type="password"
        placeholder="Confirm Password"
        value={confirm}
        onChange={(e) => formInputChange('confirmpassword', e.target.value)}
      />
      {passwordErr && <ErrorText>{passwordErr}</ErrorText>}
      <StyledButton type="submit" disabled={!validateForm()}>Sign Up</StyledButton>
    </Container>
    </form>
    )
  }

  return (
    <pcontainer>
   {newUser === null ? renderForm() : renderConfirmationForm()}
    </pcontainer>
 
  );
};

export default Signup;